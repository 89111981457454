import React from 'react'
import {getQuoteUrl, leadingZeros} from '../lib/helpers'
import PortableText from './portableText'
import Container from './container'
import '../styles/quote-single.css'
import {Link} from 'gatsby'

function BookSingle (props) {
  const {_rawDescription} = props
  //console.log(spreads)

  return (
    <Container>
      <div className="wrapper">

        <div className="quote-archive-list">

          <h3><Link to="/quotes/">Quotes</Link></h3>
          
          <div className="grid quote-archive-holder">

            <div className="quote-single width-12-12-m">

              <div class="catalog-number">
                <p><Link to={getQuoteUrl(props.catalogNumber)}>{leadingZeros(props.catalogNumber)}</Link></p>
              </div>
              <div class="quote">
              {_rawDescription && (
                <div className="description-holder">
                  {_rawDescription && <PortableText blocks={_rawDescription} />}
                </div>
              )}

              {props.source && (
                <div className="source-holder">
                  {props.source && (
                    <p class="source">–{props.source}</p>
                  )}
                </div>
              )}

              {props.links &&
                props.links.map(link => (
                <p class="quote-link">
                  <a target="_blank" rel="noreferrer" title={link.title} href={link.url}>
                    {link.title}
                  </a>
                </p>    
                ))
              }

              </div>

            </div>
          </div>

        </div>
      </div>
    </Container>
  )
}

export default BookSingle