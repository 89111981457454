import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import QuoteSingle from '../components/quote-single'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutCatsWithoutParents
} from '../lib/helpers'

export const query = graphql`
  query QuoteSingleTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }
    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }
    quote: sanityQuote(id: {eq: $id}) {
      id
      publishedAt
      source
      title
      catalogNumber
      links {
        title
        url
      }
      _rawDescription(resolveReferences: {maxDepth: 5})
    }
  }
`

const QuoteTemplate = props => {
  const {data, errors} = props

  const site = (data || {}).site

  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  const quote = data && data.quote

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      {errors && <SEO title='GraphQL Error' />}
      {quote && <SEO title={quote.title || 'Untitled'} author={site.author} description={toPlainText(quote._rawDescription)} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {quote && 
        <QuoteSingle {...quote} />
      }
    </Layout>
  )
}

export default QuoteTemplate